import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import analytics from "@goalteller/app-kit/tools/analytics";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";

import bannerContact from "../images/homePage/bannerContact.svg";
import bannerMainLogo from "../images/mainLogo.svg";
import styles from "./topnav.module.css";

const TOPNAV_LINKS = [
  {
    displayText: "Home",
    link: "/",
  },
  {
    displayText: "About",
    link: "/about",
  },
];

export default function TopNav() {
  const [navbarOpacity, setNavbarOpacity] = useState(1);
  const navbarRef = useRef<HTMLElement>(null);
  const { width } = useWindowSize();
  const router = useRouter();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const maxScroll = 200;

    const opacity = 1 - Math.min(scrollPosition / maxScroll, 1);

    setNavbarOpacity(opacity);
  };

  useEffect(() => {
    const currentNavbarRef = navbarRef.current;

    currentNavbarRef?.addEventListener("scroll", handleScroll);
    return () => {
      currentNavbarRef?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.maxwidthBlur}>
        <MaxWidthContainer>
          <nav
            className={styles.bannerContainer}
            ref={navbarRef}
            style={{ opacity: navbarOpacity }}
          >
            <div>
              <Link href="/">
                <Image
                  alt="Phone icon"
                  className={styles.mainLogo}
                  src={bannerMainLogo}
                />
              </Link>
            </div>
            {width <= BREAKPOINTS.md ? null : (
              <>
                <div className={styles.bannerLinksContainer}>
                  {TOPNAV_LINKS.map(({ displayText, link }, index) => (
                    <Link
                      className={clsx(styles.linkItem, {
                        [styles.activeItem]: router.pathname === link,
                      })}
                      href={link}
                      key={index}
                    >
                      {displayText}
                    </Link>
                  ))}
                </div>

                <div className={styles.bannerCallbackContainer}>
                  <Button
                    className={styles.contactButton}
                    onClick={() => {
                      void router.push({
                        pathname: "/book-call",
                        query: { on: router.asPath },
                      });
                      analytics.track("Book_a_call", {
                        position: "topnav",
                        text: "Book a call",
                      });
                    }}
                    variant="borderless"
                  >
                    <Image
                      alt=""
                      className={styles.contactButtonImage}
                      src={bannerContact}
                    />
                    Book a Call
                  </Button>
                </div>
              </>
            )}

            {/* hamburger */}
            <Button
              className={styles.mobileMenuIcon}
              onClick={toggleMobileMenu}
              variant="borderless"
            >
              <div
                className={`${styles.hamburgerIcon} ${
                  isMobileMenuOpen ? styles.open : ""
                }`}
              >
                <span />
                <span />
                <span />
              </div>
            </Button>
          </nav>
          {isMobileMenuOpen && (
            <div className={styles.mobileMenu}>
              {TOPNAV_LINKS.map(({ displayText, link }, index) => (
                <Link
                  className={clsx(styles.mobileLinkItem, {
                    [styles.activeItem]: router.pathname === link,
                  })}
                  href={link}
                  key={index}
                >
                  {displayText}
                </Link>
              ))}
              <div className={styles.bannerCallbackContainer}>
                <Button
                  className={styles.contactButton}
                  onClick={() => {
                    void router.push({
                      pathname: "/book-call",
                      query: { on: router.asPath },
                    });
                  }}
                  variant="borderless"
                >
                  <Image
                    alt=""
                    className={styles.contactButtonImage}
                    src={bannerContact}
                  />
                  Book a Call
                </Button>
              </div>
            </div>
          )}
        </MaxWidthContainer>
      </div>
    </div>
  );
}
